<template>
  <router-view/>
</template>

<script>
export default {
  name: 'Index',
};
</script>

<style scoped lang="">

</style>
