<template>
  <div class="full-page">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'Index',
  beforeDestroy() {
    this.$store.commit('Broadcast/resetWatchDataQuery');
  },
};
</script>

<style scoped lang="scss">

</style>
