<template>
  <div>
    <a-form-model :model="form"
                  layout="inline"
                  class="query-form">
      <a-form-model-item label="直播名称" prop="live_id">
        <select-broadcast v-model="form.live_id" style="width: 180px;"
                          @change="updateQuery('live_id', $event)"/>
      </a-form-model-item>

      <a-form-model-item>
        <a-button type="primary" :loading="loading" @click="onSearch">查询</a-button>
      </a-form-model-item>

      <a-form-model-item>
        <a-button @click="reset">重置</a-button>
      </a-form-model-item>

      <a-form-model-item>
        <a-button type="primary" :loading="downloading" @click="exportExcel">导出</a-button>
      </a-form-model-item>
    </a-form-model>

    <div class="pd-card">
      <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered
               row-key="id" class="table small-cell-table">

        <template slot="action" slot-scope="text, record">
          <div class="table-cell-wrapper">
            <a-button type="link" size="small" @click="viewDetail(record)">查询明细</a-button>
          </div>
        </template>

        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import {
  getBroadcastHeatData,
  getBroadcastHeatDataDownload,
} from '@/api/data-statistics/Index';
import Common from '@/utils/Common';

import Pagination, { getPagination } from '@/components/Pagination.vue';
import SelectBroadcast from './SelectBroadcast.vue';

export default {
  name: 'Heat',
  components: { Pagination, SelectBroadcast },
  watch: {
    '$store.state.Broadcast.watchDataQuery': {
      immediate: true,
      handler(value) {
        this.form = { live_id: value?.live_id || '' };
      },
    },
  },
  data() {
    return {
      loading: false,
      downloading: false,

      labelCol: { span: 8 },
      wrapperCol: { span: 10 },
      form: {
        live_id: '',
      },

      columns: [],
      data: [],
      pagination: getPagination(),
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    updateQuery(field, value) {
      this.$store.commit('Broadcast/setWatchDataQuery', {
        ...this.$store.state.Broadcast.watchDataQuery,
        [field]: value,
      });
    },
    reset() {
      this.$store.commit('Broadcast/setWatchDataQuery', {
        ...this.$store.state.Broadcast.watchDataQuery,
        live_id: '',
      });
      this.getData(1, this.pagination.pageSize, { live_id: '' });
    },
    onSearch() {
      this.getData(1, this.pagination.pageSize);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize, param) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      const params = {
        page: page,
        per_page: pageSize,
        live_id: param ? param.live_id : this.form.live_id,
      };
      if (this.loading) {
        return;
      }
      this.loading = true;
      const data = await getBroadcastHeatData(params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      this.data = (data?.data || []).map((i, idx) => {
        return {
          ...i,
          id: i.id || `${i.live_id}-${this.pagination.current}-${idx}`,
        };
      });
      const columns = data?.meta?.columns || [];
      columns.push({
        dataIndex: 'action',
        key: 'action',
        width: '120px',
        title: '操作',
        scopedSlots: { customRender: 'action' },
      });
      columns.forEach((i) => {
        i.width = '120px';
        i.align = 'center';

        if (i.key === 'live_id') {
          i.width = '80px';
        }
        if (i.key === 'live_name') {
          i.width = '180px';
        }
      });
      this.columns = columns;

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },

    exportExcel() {
      if (this.downloading) {
        return;
      }
      this.downloading = true;

      const params = {
        page: this.pagination.current,
        per_page: this.pagination.pageSize,
        live_id: this.form.live_id,
        is_export: 1,
      };
      getBroadcastHeatDataDownload(params).then(() => {
        this.$message.success('导出成功');
      }).catch(() => {
        this.$message.error('导出失败');
      }).finally(() => {
        this.downloading = false;
      });
    },

    viewDetail(record) {
      this.$router.push({
        path: '/index/data-statistics/broadcast/query/heat-detail',
        query: {
          id: record.live_id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.query-form {
  padding: 5px 15px 10px 20px;
}
</style>
