<template>
  <div>
    <a-form-model
      ref="formModalRef"
      :model="form"
      layout="inline"
      class="query-form">
      <a-form-model-item label="直播名称" prop="live_id">
        <select-broadcast v-model="form.live_id"
                          :max-count="3"
                          style="width: 180px;"
                          @change="updateQuery('live_id', $event)"/>
      </a-form-model-item>
      <a-form-model-item label="岗位" prop="post_id">
        <select-job-pure v-model="form.post_id" @change="updateQuery('post_id', $event)"/>
      </a-form-model-item>
      <a-form-model-item label="组织机构" prop="org_id">
        <select-org v-model="form.org_id" :show-label="false" class="fix"
                    @change="updateQuery('org_id', $event)"/>
      </a-form-model-item>

      <a-form-model-item>
        <a-button type="primary" :loading="loading" @click="onSearch">查询</a-button>
      </a-form-model-item>

      <a-form-model-item>
        <a-button @click="reset">重置</a-button>
      </a-form-model-item>

      <a-form-model-item>
        <a-button type="primary" :loading="downloading" @click="exportExcel">导出</a-button>
      </a-form-model-item>
    </a-form-model>

    <div class="pd-card">
      <a-table ref="aaa" :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered
               row-key="id" class="table small-cell-table">
        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import {
  getBroadcastWatchData,
  getBroadcastWatchDataDownload,
} from '@/api/data-statistics/Index';
import Common from '@/utils/Common';

import SelectJobPure from '@/components/SelectJobPure.vue';
import SelectOrg from '@/components/SelectOrg.vue';
import Pagination, { getPagination } from '@/components/Pagination.vue';
import SelectBroadcast from './SelectBroadcast.vue';

const columns = [
  {
    width: '80px',
    align: 'center',
    dataIndex: 'live_id',
    key: 'live_id',
    title: '直播ID',
  },
  {
    width: '160px',
    align: 'center',
    dataIndex: 'name',
    key: 'name',
    title: '直播名称',
  },
  {
    width: '140px',
    align: 'center',
    dataIndex: 'truename',
    key: 'truename',
    title: '学员姓名',
  },
  {
    width: '160px',
    align: 'center',
    dataIndex: 'idcard',
    key: 'idcard',
    title: '学员身份证号',
  },
  {
    width: '100px',
    align: 'center',
    dataIndex: 'postname',
    key: 'postname',
    title: '岗位',
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'upname',
    key: 'upname',
    title: '大区',
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'orgname',
    key: 'orgname',
    title: '经销店',
  },
  {
    width: '100px',
    align: 'center',
    dataIndex: 'user_Live_time_num',
    key: 'user_Live_time_num',
    title: '观看直播累计时长（分）',
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'user_playback_time_num',
    key: 'user_playback_time_num',
    title: '回看点播累计时长（分）',
  },
];

export default {
  name: 'Watch',
  components: { SelectJobPure, SelectOrg, Pagination, SelectBroadcast },
  watch: {
    '$store.state.Broadcast.watchDataQuery': {
      immediate: true,
      handler(value) {
        this.form = { ...value };
      },
    },
  },
  data() {
    return {
      loading: false,
      downloading: false,

      form: {
        live_id: '',
        post_id: '',
        org_id: '',
      },

      columns: columns,
      data: [],
      pagination: getPagination(),
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    updateQuery(field, value) {
      this.$store.commit('Broadcast/setWatchDataQuery', {
        ...this.form,
        [field]: value,
      });
    },
    reset() {
      this.$store.commit('Broadcast/resetWatchDataQuery');
      this.getData(1, this.pagination.pageSize, {
        live_id: '',
        post_id: '',
        org_id: '',
      });
    },
    onSearch() {
      this.getData(1, this.pagination.pageSize);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize, param) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      if (!this.form?.live_id && !param?.live_id) {
        this.data = [];
        this.$message.info('请选择直播');
        return;
      }

      const params = {
        page: page,
        per_page: pageSize,
        ...(param || this.form),
      };
      if (this.loading) {
        return;
      }
      this.loading = true;
      const data = await getBroadcastWatchData(params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      this.data = (data?.data || []).map((i, idx) => {
        return {
          ...i,
          id: i.id || `${i.live_id}-${this.pagination.current}-${idx}`,
        };
      });

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },

    exportExcel() {
      if (!this.form?.live_id) {
        this.data = [];
        this.$message.info('请选择直播');
        return;
      }
      if (this.downloading) {
        return;
      }
      this.downloading = true;

      const params = {
        page: this.pagination.current,
        per_page: this.pagination.pageSize,
        is_export: 1,
        ...this.form,
      };
      getBroadcastWatchDataDownload(params).then(() => {
        this.$message.success('导出成功');
      }).catch(() => {
        this.$message.error('导出失败');
      }).finally(() => {
        this.downloading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.fix {
  transform: translateY(4px);
}

.query-form {
  padding: 5px 15px 10px 20px;
}
</style>
