<template>
  <div class="data-statistics-broadcast-detail-broadcast full-page">
    <div class="flex flex-start mb-20">
      <StatisticsCard :value="broadcastValue"/>
      <a-button type="primary" :loading="downloading" class="ml-40" @click="exportExcel">导出</a-button>
      <a-button @click="back">返回</a-button>
    </div>

    <div class="mb-20 block">
      <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered
               row-key="number" class="table small-cell-table">
        <template slot="start_time" slot-scope="text">
          <div class="table-cell-wrapper">{{ formatDateTime(text) }}</div>
        </template>
        <template slot="end_time" slot-scope="text">
          <div class="table-cell-wrapper">{{ formatDateTime(text) }}</div>
        </template>
        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import {getBroadcastLiveDetail, getBroadcastLiveDetailDownload} from '@/api/data-statistics/Index';

import Pagination, {getPagination} from '@/components/Pagination';
import StatisticsCard from './components/StatisticsCard';
import Common from '@/utils/Common';

const columns = [
  {
    width: '60px',
    align: 'center',
    dataIndex: 'number',
    key: 'number',
    title: '序号',
  },
  {
    width: '160px',
    align: 'center',
    dataIndex: 'start_time',
    key: 'start_time',
    title: '开始时间',
    scopedSlots: {customRender: 'start_time'},
  },
  {
    width: '160px',
    align: 'center',
    dataIndex: 'end_time',
    key: 'end_time',
    title: '结束时间',
    scopedSlots: {customRender: 'end_time'},
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'push_duration',
    key: 'push_duration',
    title: '时长（分钟）',
  },
];

export default {
  name: 'Broadcast',
  components: {Pagination, StatisticsCard},
  data() {
    return {
      loading: false,
      downloading: false,
      id: '',

      broadcastValue: {title: '实际直播总时长：', val: 0, unit: '分钟'},

      data: [],
      pagination: getPagination(),
      columns: columns,
    };
  },
  created() {
    this.id = this.$route.query?.id || '';

    this.id && this.getData();
  },
  methods: {
    back() {
      this.$router.back();
    },

    formatDateTime(time) {
      return Common.formatDateTime(time * 1000);
    },

    async getData(page, pageSize) {
      if (this.loading) {return;}
      this.loading = true;

      const params = {
        id: this.id,
        page, per_page: pageSize,
      };

      const data = await getBroadcastLiveDetail(params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return this.$message.error(data?.message || '查询直播明细失败');
      }
      this.data = data?.data || [];

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);

      this.broadcastValue = {...this.broadcastValue, val: data?.meta?.totalPush || 0};
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },

    exportExcel() {
      if (this.downloading) {return;}
      this.downloading = true;

      const params = {
        id: this.id,
        page: this.pagination.current,
        per_page: this.pagination.pageSize,
        is_down: 1,
      };

      getBroadcastLiveDetailDownload(params).finally(() => this.downloading = false).catch(() => this.downloading = false);
    },
  },
};
</script>

<style scoped lang="scss">
.data-statistics-broadcast-detail-broadcast {
}
</style>
